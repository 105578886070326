/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The ContratoCarenciaViewModel model module.
* @module model/ContratoCarenciaViewModel
* @version v1
*/
export default class ContratoCarenciaViewModel {
    /**
    * Constructs a new <code>ContratoCarenciaViewModel</code>.
    * @alias module:model/ContratoCarenciaViewModel
    * @class
    */

    constructor() {
        

        
        

        

        
    }

    /**
    * Constructs a <code>ContratoCarenciaViewModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ContratoCarenciaViewModel} obj Optional instance to populate.
    * @return {module:model/ContratoCarenciaViewModel} The populated <code>ContratoCarenciaViewModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ContratoCarenciaViewModel();

            
            
            

            if (Object.prototype.hasOwnProperty.call(data, 'agencia')) {
                obj['agencia'] = ApiClient.convertToType(data['agencia'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'empresa')) {
                obj['empresa'] = ApiClient.convertToType(data['empresa'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'numeroContrato')) {
                obj['numeroContrato'] = ApiClient.convertToType(data['numeroContrato'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'cliente')) {
                obj['cliente'] = ApiClient.convertToType(data['cliente'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'descricao')) {
                obj['descricao'] = ApiClient.convertToType(data['descricao'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'produto')) {
                obj['produto'] = ApiClient.convertToType(data['produto'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'valorFinanciado')) {
                obj['valorFinanciado'] = ApiClient.convertToType(data['valorFinanciado'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'saldoDevedor')) {
                obj['saldoDevedor'] = ApiClient.convertToType(data['saldoDevedor'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'emissao')) {
                obj['emissao'] = ApiClient.convertToType(data['emissao'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'situacao')) {
                obj['situacao'] = ApiClient.convertToType(data['situacao'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'tipoCobranca')) {
                obj['tipoCobranca'] = ApiClient.convertToType(data['tipoCobranca'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'qtdParcelasPagas')) {
                obj['qtdParcelasPagas'] = ApiClient.convertToType(data['qtdParcelasPagas'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'qtdParcelas')) {
                obj['qtdParcelas'] = ApiClient.convertToType(data['qtdParcelas'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'primeiroVencimento')) {
                obj['primeiroVencimento'] = ApiClient.convertToType(data['primeiroVencimento'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'ultimoPagamento')) {
                obj['ultimoPagamento'] = ApiClient.convertToType(data['ultimoPagamento'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'proximaParcela')) {
                obj['proximaParcela'] = ApiClient.convertToType(data['proximaParcela'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'proximoVencimento')) {
                obj['proximoVencimento'] = ApiClient.convertToType(data['proximoVencimento'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'proximaParcelaPosRolagem')) {
                obj['proximaParcelaPosRolagem'] = ApiClient.convertToType(data['proximaParcelaPosRolagem'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'proximoVencimentoPosRolagem')) {
                obj['proximoVencimentoPosRolagem'] = ApiClient.convertToType(data['proximoVencimentoPosRolagem'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'ultimoVencimento')) {
                obj['ultimoVencimento'] = ApiClient.convertToType(data['ultimoVencimento'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'dataContrato')) {
                obj['dataContrato'] = ApiClient.convertToType(data['dataContrato'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'extrato')) {
                obj['extrato'] = ApiClient.convertToType(data['extrato'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'declaracao')) {
                obj['declaracao'] = ApiClient.convertToType(data['declaracao'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'novaDataParcela')) {
                obj['novaDataParcela'] = ApiClient.convertToType(data['novaDataParcela'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'exibeExtrato')) {
                obj['exibeExtrato'] = ApiClient.convertToType(data['exibeExtrato'], 'Boolean');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'exibeDeclaracao')) {
                obj['exibeDeclaracao'] = ApiClient.convertToType(data['exibeDeclaracao'], 'Boolean');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'regraRolagem')) {
                obj['regraRolagem'] = ApiClient.convertToType(data['regraRolagem'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} agencia
    */
    agencia = undefined;
    /**
    * @member {String} empresa
    */
    empresa = undefined;
    /**
    * @member {String} numeroContrato
    */
    numeroContrato = undefined;
    /**
    * @member {String} cliente
    */
    cliente = undefined;
    /**
    * @member {String} descricao
    */
    descricao = undefined;
    /**
    * @member {String} produto
    */
    produto = undefined;
    /**
    * @member {Number} valorFinanciado
    */
    valorFinanciado = undefined;
    /**
    * @member {Number} saldoDevedor
    */
    saldoDevedor = undefined;
    /**
    * @member {Date} emissao
    */
    emissao = undefined;
    /**
    * @member {String} situacao
    */
    situacao = undefined;
    /**
    * @member {String} tipoCobranca
    */
    tipoCobranca = undefined;
    /**
    * @member {Number} qtdParcelasPagas
    */
    qtdParcelasPagas = undefined;
    /**
    * @member {Number} qtdParcelas
    */
    qtdParcelas = undefined;
    /**
    * @member {Date} primeiroVencimento
    */
    primeiroVencimento = undefined;
    /**
    * @member {Date} ultimoPagamento
    */
    ultimoPagamento = undefined;
    /**
    * @member {String} proximaParcela
    */
    proximaParcela = undefined;
    /**
    * @member {Date} proximoVencimento
    */
    proximoVencimento = undefined;
    /**
    * @member {String} proximaParcelaPosRolagem
    */
    proximaParcelaPosRolagem = undefined;
    /**
    * @member {Date} proximoVencimentoPosRolagem
    */
    proximoVencimentoPosRolagem = undefined;
    /**
    * @member {Date} ultimoVencimento
    */
    ultimoVencimento = undefined;
    /**
    * @member {Date} dataContrato
    */
    dataContrato = undefined;
    /**
    * @member {String} extrato
    */
    extrato = undefined;
    /**
    * @member {String} declaracao
    */
    declaracao = undefined;
    /**
    * @member {Date} novaDataParcela
    */
    novaDataParcela = undefined;
    /**
    * @member {Boolean} exibeExtrato
    */
    exibeExtrato = undefined;
    /**
    * @member {Boolean} exibeDeclaracao
    */
    exibeDeclaracao = undefined;
    /**
    * @member {Number} regraRolagem
    */
    regraRolagem = undefined;








}



/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import ParcelaRolagem from './ParcelaRolagem';





/**
* The RolagemRequest model module.
* @module model/RolagemRequest
* @version v1
*/
export default class RolagemRequest {
    /**
    * Constructs a new <code>RolagemRequest</code>.
    * @alias module:model/RolagemRequest
    * @class
    * @param empresa {String} 
    * @param agencia {String} 
    * @param contrato {String} 
    * @param parcelas {Array.<module:model/ParcelaRolagem>} 
    */

    constructor(empresa, agencia, contrato, parcelas) {
        

        
        

        this['empresa'] = empresa;this['agencia'] = agencia;this['contrato'] = contrato;this['parcelas'] = parcelas;

        
    }

    /**
    * Constructs a <code>RolagemRequest</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/RolagemRequest} obj Optional instance to populate.
    * @return {module:model/RolagemRequest} The populated <code>RolagemRequest</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RolagemRequest();

            
            
            

            if (Object.prototype.hasOwnProperty.call(data, 'empresa')) {
                obj['empresa'] = ApiClient.convertToType(data['empresa'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'agencia')) {
                obj['agencia'] = ApiClient.convertToType(data['agencia'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'contrato')) {
                obj['contrato'] = ApiClient.convertToType(data['contrato'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'parcelas')) {
                obj['parcelas'] = ApiClient.convertToType(data['parcelas'], [ParcelaRolagem]);
            }
        }
        return obj;
    }

    /**
    * @member {String} empresa
    */
    empresa = undefined;
    /**
    * @member {String} agencia
    */
    agencia = undefined;
    /**
    * @member {String} contrato
    */
    contrato = undefined;
    /**
    * @member {Array.<module:model/ParcelaRolagem>} parcelas
    */
    parcelas = undefined;








}



/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import ContratoCarenciaViewModel from '../model/ContratoCarenciaViewModel';
import ContratosView from '../model/ContratosView';
import ParcelaBoletoViewModel from '../model/ParcelaBoletoViewModel';
import ParcelaCaranciaViewModel from '../model/ParcelaCaranciaViewModel';
import ParcelasResumo from '../model/ParcelasResumo';
import ParcelasView from '../model/ParcelasView';
import ReportResponseModel from '../model/ReportResponseModel';
import RolagemRequest from '../model/RolagemRequest';
import SicredErrorResponse from '../model/SicredErrorResponse';
import Parcela2ViaViewModel from '../model/Parcela2ViaViewModel';

/**
* Contrato service.
* @module api/ContratoApi
* @version v1
*/
export default class ContratoApi {

  /**
  * Constructs a new ContratoApi. 
  * @alias module:api/ContratoApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the boleto operation.
   * @callback module:api/ContratoApi~boletoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ParcelaBoletoViewModel} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Boleto
   * @param {String} empresa Código da empresa.
   * @param {String} simulacao Código da simulação.
   * @param {module:api/ContratoApi~boletoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ParcelaBoletoViewModel}
   */
  boleto(empresa, simulacao, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling boleto");
    }

    // verify the required parameter 'simulacao' is set
    if (simulacao === undefined || simulacao === null) {
      throw new Error("Missing the required parameter 'simulacao' when calling boleto");
    }

    let pathParams = {
      'empresa': empresa,
      'simulacao': simulacao
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = ParcelaBoletoViewModel;

    return this.apiClient.callApi(
      '/api/contratos/boleto/{empresa}/{simulacao}/QuitacaoBoleto', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the conexaoServicoNPC operation.
   * @callback module:api/ContratoApi~conexaoServicoNPCCallback
   * @param {String} error Error message, if any.
   * @param {'String'} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConexaoServicoNPC
   * @param {module:api/ContratoApi~conexaoServicoNPCCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link 'String'}
   */
  conexaoServicoNPC(callback) {
    let postBody = null;

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/api/contratos/ConexaoServicoNPC', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaContratoCCB operation.
   * @callback module:api/ContratoApi~consultaContratoCCBCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ReportResponseModel} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConsultaContratoCCB
   * @param {String} empresa Código da empresa.
   * @param {String} agencia Código da agência.
   * @param {String} contrato Código do contrato.
   * @param {module:api/ContratoApi~consultaContratoCCBCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ReportResponseModel}
   */
  consultaContratoCCB(empresa, agencia, contrato, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaContratoCCB");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling consultaContratoCCB");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling consultaContratoCCB");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia,
      'contrato': contrato
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = ReportResponseModel;

    return this.apiClient.callApi(
      '/api/contratos/{empresa}/{agencia}/{contrato}/ccb', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaCertificadoSeguroCallback operation.
   * @callback module:api/ContratoApi~consultaCertificadoSeguroCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ReportResponseModel} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
  * ConsultaCertificadoSeguro
  * @param {String} empresa Código da empresa.
  * @param {String} agencia Código da agência.
  * @param {String} contrato Código do contrato.
  * @param {module:api/ContratoApi~consultaCertificadoSeguroCallback} callback The callback function, accepting three arguments: error, data, response
  * data is of type: {@link module:model/ReportResponseModel}
  */
  consultaCertificadoSeguro(empresa, agencia, contrato, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaCertificadoSeguro");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling consultaCertificadoSeguro");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling consultaCertificadoSeguro");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia,
      'contrato': contrato
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = ReportResponseModel;

    return this.apiClient.callApi(
      '/api/contratos/{empresa}/{agencia}/{contrato}/certificado-seguro', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaContratosPassiveisCarencia operation.
   * @callback module:api/ContratoApi~consultaContratosPassiveisCarenciaCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/ContratoCarenciaViewModel>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConsultaContratosPassiveisCarencia
   * @param {String} empresa Código da empresa.
   * @param {String} cpfcnpj CPF ou CNPJ do cliente.
   * @param {module:api/ContratoApi~consultaContratosPassiveisCarenciaCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/ContratoCarenciaViewModel>}
   */
  consultaContratosPassiveisCarencia(empresa, cpfcnpj, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaContratosPassiveisCarencia");
    }

    // verify the required parameter 'cpfcnpj' is set
    if (cpfcnpj === undefined || cpfcnpj === null) {
      throw new Error("Missing the required parameter 'cpfcnpj' when calling consultaContratosPassiveisCarencia");
    }

    let pathParams = {
      'empresa': empresa,
      'cpfcnpj': cpfcnpj
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = [ContratoCarenciaViewModel];

    return this.apiClient.callApi(
      '/api/ContratosCarencia/{empresa}/{cpfcnpj}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaContratosPorCliente operation.
   * @callback module:api/ContratoApi~consultaContratosPorClienteCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/ContratosView>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConsultaClienteContratos
   * @param {String} empresa Código da empresa.
   * @param {String} cpfcnpjcliente CPF ou CNPJ do cliente.
   * @param {String} situacao Situação do contrato. (A)berto, (L)iquidado ou (T)odas.
   * @param {module:api/ContratoApi~consultaContratosPorClienteCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/ContratosView>}
   */
  consultaContratosPorCliente(empresa, cpfcnpjcliente, situacao, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaContratosPorCliente");
    }

    // verify the required parameter 'cpfcnpjcliente' is set
    if (cpfcnpjcliente === undefined || cpfcnpjcliente === null) {
      throw new Error("Missing the required parameter 'cpfcnpjcliente' when calling consultaContratosPorCliente");
    }

    // verify the required parameter 'situacao' is set
    if (situacao === undefined || situacao === null) {
      throw new Error("Missing the required parameter 'situacao' when calling consultaContratosPorCliente");
    }

    let pathParams = {
      'empresa': empresa
    };
    let queryParams = {
      'cpfcnpjcliente': cpfcnpjcliente,
      'situacao': situacao
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = [ContratosView];

    return this.apiClient.callApi(
      '/api/contratos/{empresa}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaDeclaracaoContrato operation.
   * @callback module:api/ContratoApi~consultaDeclaracaoContratoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ReportResponseModel} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConsultaDeclaracaoContrato
   * @param {String} empresa Código da empresa.
   * @param {String} agencia Código da agência.
   * @param {String} contrato Código do contrato.
   * @param {module:api/ContratoApi~consultaDeclaracaoContratoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ReportResponseModel}
   */
  consultaDeclaracaoContrato(empresa, agencia, contrato, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaDeclaracaoContrato");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling consultaDeclaracaoContrato");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling consultaDeclaracaoContrato");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia,
      'contrato': contrato
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = ReportResponseModel;

    return this.apiClient.callApi(
      '/api/contratos/{empresa}/{agencia}/{contrato}/declaracao', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaExtratoContrato operation.
   * @callback module:api/ContratoApi~consultaExtratoContratoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ReportResponseModel} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConsultaExtratoContrato
   * @param {String} empresa Código da empresa.
   * @param {String} agencia Código da agência.
   * @param {String} contrato Código do contrato.
   * @param {module:api/ContratoApi~consultaExtratoContratoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ReportResponseModel}
   */
  consultaExtratoContrato(empresa, agencia, contrato, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaExtratoContrato");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling consultaExtratoContrato");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling consultaExtratoContrato");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia,
      'contrato': contrato
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = ReportResponseModel;

    return this.apiClient.callApi(
      '/api/contratos/{empresa}/{agencia}/{contrato}/extrato', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaParcelas operation.
   * @callback module:api/ContratoApi~consultaParcelasCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/ParcelasView>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConsultaParcelas
   * @param {String} empresa empresa
   * @param {String} agencia agencia
   * @param {String} contrato contrato
   * @param {Date} dataPagamento Data para pagamento.
   * @param {String} situacao Situacao A,L ou T.
   * @param {module:api/ContratoApi~consultaParcelasCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/ParcelasView>}
   */
  consultaParcelas(empresa, agencia, contrato, dataPagamento, situacao, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaParcelas");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling consultaParcelas");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling consultaParcelas");
    }

    // verify the required parameter 'dataPagamento' is set
    if (dataPagamento === undefined || dataPagamento === null) {
      throw new Error("Missing the required parameter 'dataPagamento' when calling consultaParcelas");
    }

    // verify the required parameter 'situacao' is set
    if (situacao === undefined || situacao === null) {
      throw new Error("Missing the required parameter 'situacao' when calling consultaParcelas");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia,
      'contrato': contrato
    };
    let queryParams = {
      'dataPagamento': dataPagamento,
      'situacao': situacao
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = [ParcelasView];

    return this.apiClient.callApi(
      '/api/contratos/{empresa}/{agencia}/{contrato}/parcelas', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaParcelasSimulacao operation.
   * @callback module:api/ContratoApi~consultaParcelasSimulacaoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ParcelasResumo} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConsultaParcelasSimulacao
   * @param {String} empresa 
   * @param {String} agencia 
   * @param {String} contrato 
   * @param {Array.<String>} numeroParcelas 
   * @param {Date} dataPagamento 
   * @param {module:api/ContratoApi~consultaParcelasSimulacaoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ParcelasResumo}
   */
  consultaParcelasSimulacao(empresa, agencia, contrato, numeroParcelas, dataPagamento, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaParcelasSimulacao");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling consultaParcelasSimulacao");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling consultaParcelasSimulacao");
    }

    // verify the required parameter 'numeroParcelas' is set
    if (numeroParcelas === undefined || numeroParcelas === null) {
      throw new Error("Missing the required parameter 'numeroParcelas' when calling consultaParcelasSimulacao");
    }

    // verify the required parameter 'dataPagamento' is set
    if (dataPagamento === undefined || dataPagamento === null) {
      throw new Error("Missing the required parameter 'dataPagamento' when calling consultaParcelasSimulacao");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia,
      'contrato': contrato
    };
    let queryParams = {
      'numeroParcelas': this.apiClient.buildCollectionParam(numeroParcelas, 'multi'),
      'dataPagamento': dataPagamento
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = ParcelasResumo;

    return this.apiClient.callApi(
      '/api/contratos/{empresa}/{agencia}/{contrato}/simulacao', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaParcelasCarencia operation.
   * @callback module:api/ContratoApi~consultaParcelasCarenciaCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/ParcelaCaranciaViewModel>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConsultaParcelasCarencia
   * @param {String} empresa Empresa
   * @param {String} agencia Agência
   * @param {String} contrato Contrato
   * @param {module:api/ContratoApi~consultaParcelasCarenciaCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/ParcelaCaranciaViewModel>}
   */
  consultaParcelasCarencia(empresa, agencia, contrato, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaParcelasCarencia");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling consultaParcelasCarencia");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling consultaParcelasCarencia");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia,
      'contrato': contrato
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = [ParcelaCaranciaViewModel];

    return this.apiClient.callApi(
      '/api/ParcelasCarencia/{empresa}/{agencia}/{contrato}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaQuitacaoContrato operation.
   * @callback module:api/ContratoApi~consultaQuitacaoContratoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ParcelasResumo} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConsultaQuitacaoContrato
   * @param {String} empresa 
   * @param {String} agencia 
   * @param {String} contrato 
   * @param {Date} dataPagamento 
   * @param {String} situacao 
   * @param {module:api/ContratoApi~consultaQuitacaoContratoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ParcelasResumo}
   */
  consultaQuitacaoContrato(empresa, agencia, contrato, dataPagamento, situacao, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaQuitacaoContrato");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling consultaQuitacaoContrato");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling consultaQuitacaoContrato");
    }

    // verify the required parameter 'dataPagamento' is set
    if (dataPagamento === undefined || dataPagamento === null) {
      throw new Error("Missing the required parameter 'dataPagamento' when calling consultaQuitacaoContrato");
    }

    // verify the required parameter 'situacao' is set
    if (situacao === undefined || situacao === null) {
      throw new Error("Missing the required parameter 'situacao' when calling consultaQuitacaoContrato");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia,
      'contrato': contrato
    };
    let queryParams = {
      'dataPagamento': dataPagamento,
      'situacao': situacao
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = ParcelasResumo;

    return this.apiClient.callApi(
      '/api/contratos/{empresa}/{agencia}/{contrato}/Quitacao', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaQuitacaoContratoSimulacao operation.
   * @callback module:api/ContratoApi~consultaQuitacaoContratoSimulacaoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ParcelasResumo} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConsultaBoletoQuitacaoContratoSimulacao
   * @param {String} empresa Código da empresa.
   * @param {String} agencia Código da agência.
   * @param {String} contrato código do contrato
   * @param {Date} dataPagamento Data do pagamento
   * @param {String} situacao Situacao do contrato. (A)berto ou (L)iquidado ou (T)Atrasado.
   * @param {module:api/ContratoApi~consultaQuitacaoContratoSimulacaoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ParcelasResumo}
   */
  consultaQuitacaoContratoSimulacao(empresa, agencia, contrato, dataPagamento, situacao, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaQuitacaoContratoSimulacao");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling consultaQuitacaoContratoSimulacao");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling consultaQuitacaoContratoSimulacao");
    }

    // verify the required parameter 'dataPagamento' is set
    if (dataPagamento === undefined || dataPagamento === null) {
      throw new Error("Missing the required parameter 'dataPagamento' when calling consultaQuitacaoContratoSimulacao");
    }

    // verify the required parameter 'situacao' is set
    if (situacao === undefined || situacao === null) {
      throw new Error("Missing the required parameter 'situacao' when calling consultaQuitacaoContratoSimulacao");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia,
      'contrato': contrato
    };
    let queryParams = {
      'dataPagamento': dataPagamento,
      'situacao': situacao
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = ParcelasResumo;

    return this.apiClient.callApi(
      '/api/contratos/{empresa}/{agencia}/{contrato}/QuitacaoRegistra', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaResumoContrato operation.
   * @callback module:api/ContratoApi~consultaResumoContratoCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/ContratosView>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConsultaResumoContrato
   * @param {String} empresa 
   * @param {String} contrato 
   * @param {module:api/ContratoApi~consultaResumoContratoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/ContratosView>}
   */
  consultaResumoContrato(empresa, contrato, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaResumoContrato");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling consultaResumoContrato");
    }

    let pathParams = {
      'empresa': empresa,
      'contrato': contrato
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = [ContratosView];

    return this.apiClient.callApi(
      '/api/contratos/{empresa}/{contrato}/resumo', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the diaUtil operation.
   * @callback module:api/ContratoApi~diaUtilCallback
   * @param {String} error Error message, if any.
   * @param {'Boolean'} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * DiaUtil
   * @param {String} empresa Código da empresa.
   * @param {String} agencia Código da agência.
   * @param {Date} data Data referência.
   * @param {module:api/ContratoApi~diaUtilCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link 'Boolean'}
   */
  diaUtil(empresa, agencia, data, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling diaUtil");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling diaUtil");
    }

    // verify the required parameter 'data' is set
    if (data === undefined || data === null) {
      throw new Error("Missing the required parameter 'data' when calling diaUtil");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia
    };
    let queryParams = {
      'data': data
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = 'Boolean';

    return this.apiClient.callApi(
      '/api/contratos/DiaUtil/{empresa}/{agencia}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the emailBoleto operation.
   * @callback module:api/ContratoApi~emailBoletoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ParcelaBoletoViewModel} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * EmailBoleto
   * @param {String} empresa Código da empresa.
   * @param {String} simulacao Código da simulação.
   * @param {String} email E-mail.
   * @param {module:api/ContratoApi~emailBoletoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ParcelaBoletoViewModel}
   */
  emailBoleto(empresa, simulacao, email, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling emailBoleto");
    }

    // verify the required parameter 'simulacao' is set
    if (simulacao === undefined || simulacao === null) {
      throw new Error("Missing the required parameter 'simulacao' when calling emailBoleto");
    }

    // verify the required parameter 'email' is set
    if (email === undefined || email === null) {
      throw new Error("Missing the required parameter 'email' when calling emailBoleto");
    }

    let pathParams = {
      'empresa': empresa,
      'simulacao': simulacao,
      'email': email
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = ParcelaBoletoViewModel;

    return this.apiClient.callApi(
      '/api/contratos/email-boleto/{empresa}/{simulacao}/{email}/QuitacaoBoleto', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the executarRolagem operation.
   * @callback module:api/ContratoApi~executarRolagemCallback
   * @param {String} error Error message, if any.
   * @param {'Number'} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ExecutarRolagem
   * @param {String} empresa 
   * @param {String} agencia 
   * @param {String} contrato 
   * @param {String} parcela 
   * @param {Date} vencimento 
   * @param {module:api/ContratoApi~executarRolagemCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link 'Number'}
   */
  executarRolagem(empresa, agencia, contrato, parcela, vencimento, callback) {
    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling executarRolagem");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling executarRolagem");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling executarRolagem");
    }

    // verify the required parameter 'parcela' is set
    if (parcela === undefined || parcela === null) {
      throw new Error("Missing the required parameter 'parcela' when calling executarRolagem");
    }

    // verify the required parameter 'vencimento' is set
    if (vencimento === undefined || vencimento === null) {
      throw new Error("Missing the required parameter 'vencimento' when calling executarRolagem");
    }

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let postBody = {
      'Empresa': empresa,
      'Agencia': agencia,
      'Contrato': contrato,
      'Parcelas': parcela,
      'Vencimento': vencimento
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = 'Number';

    return this.apiClient.callApi(
      '/api/ExecutarRolagem', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the podeAcessarSolicitacaoCarencia operation.
   * @callback module:api/ContratoApi~podeAcessarSolicitacaoCarenciaCallback
   * @param {String} error Error message, if any.
   * @param {'Boolean'} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * podeAcessarSolicitacaoCarencia
   * @param {String} empresa Código da empresa.
   * @param {module:api/ContratoApi~podeAcessarSolicitacaoCarenciaCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link 'Boolean'}
   */
  podeAcessarSolicitacaoCarencia(empresa, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling podeAcessarSolicitacaoCarencia");
    }

    let pathParams = {
      'empresa': empresa
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = 'Boolean';

    return this.apiClient.callApi(
      '/api/podeAcessarSolicitacaoCarencia/{empresa}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the proximoDiaUtil operation.
   * @callback module:api/ContratoApi~proximoDiaUtilCallback
   * @param {String} error Error message, if any.
   * @param {'Date'} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ProximoDiaUtil
   * @param {String} empresa Código da empresa.
   * @param {String} agencia Código da agência.
   * @param {Date} data Data referência.
   * @param {module:api/ContratoApi~proximoDiaUtilCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link 'Date'}
   */
  proximoDiaUtil(empresa, agencia, data, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling proximoDiaUtil");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling proximoDiaUtil");
    }

    // verify the required parameter 'data' is set
    if (data === undefined || data === null) {
      throw new Error("Missing the required parameter 'data' when calling proximoDiaUtil");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia
    };
    let queryParams = {
      'data': data
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = 'Date';

    return this.apiClient.callApi(
      '/api/contratos/ProximoDiaUtil/{empresa}/{agencia}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the diariaRodando operation.
   * @callback module:api/ContratoApi~diariaRodandoCallback
   * @param {String} error Error message, if any.
   * @param {'Boolean'} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * RodandoDiaria
   * @param {String} empresa Código da empresa.
   * @param {module:api/ContratoApi~diariaRodandoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link 'Boolean'}
   */
  diariaRodando(empresa, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling diariaRodando");
    }

    let pathParams = {
      'empresa': empresa
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = 'Boolean';

    return this.apiClient.callApi(
      '/api/RodandoDiaria/{empresa}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
   * Callback function to receive the result of the consultaParcelas2Via operation.
   * @callback module:api/ContratoApi~consultaParcelas2ViaCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Parcela2ViaViewModel>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ConsultaParcelas2Via
   * @param {String} empresa Empresa
   * @param {String} agencia Agência
   * @param {String} contrato Contrato
   * @param {module:api/ContratoApi~consultaParcelas2ViaCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Parcela2ViaViewModel>}
   */
  consultaParcelas2Via(empresa, agencia, contrato, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaParcelasCarencia");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling consultaParcelasCarencia");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling consultaParcelasCarencia");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia,
      'contrato': contrato
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = [Parcela2ViaViewModel];

    return this.apiClient.callApi(
      '/api/Parcelas2Via/{empresa}/{agencia}/{contrato}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  /**
     * Callback function to receive the result of the consultaAditivoReneg operation.
     * @callback module:api/ContratoApi~consultaAditivoRenegCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ReportResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

  /**
   * ConsultaAditivoReneg
   * @param {String} empresa Código da empresa.
   * @param {String} agencia Código da agência.
   * @param {String} contrato Código do contrato.
   * @param {module:api/ContratoApi~consultaAditivoRenegCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ReportResponseModel}
   */
  consultaAditivoReneg(empresa, agencia, contrato, callback) {
    let postBody = null;

    // verify the required parameter 'empresa' is set
    if (empresa === undefined || empresa === null) {
      throw new Error("Missing the required parameter 'empresa' when calling consultaAditivoReneg");
    }

    // verify the required parameter 'agencia' is set
    if (agencia === undefined || agencia === null) {
      throw new Error("Missing the required parameter 'agencia' when calling consultaContratoCCB");
    }

    // verify the required parameter 'contrato' is set
    if (contrato === undefined || contrato === null) {
      throw new Error("Missing the required parameter 'contrato' when calling consultaContratoCCB");
    }

    let pathParams = {
      'empresa': empresa,
      'agencia': agencia,
      'contrato': contrato
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['oauth2'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = ReportResponseModel;

    return this.apiClient.callApi(
      '/api/contratos/{empresa}/{agencia}/{contrato}/aditivo', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
}
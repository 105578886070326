/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from './ApiClient';
import Acesso from './model/Acesso';
import ContratoCarenciaViewModel from './model/ContratoCarenciaViewModel';
import ContratosView from './model/ContratosView';
import ParcelaBoletoViewModel from './model/ParcelaBoletoViewModel';
import ParcelaCaranciaViewModel from './model/ParcelaCaranciaViewModel';
import ParcelaRolagem from './model/ParcelaRolagem';
import ParcelasResumo from './model/ParcelasResumo';
import ParcelasView from './model/ParcelasView';
import ReportResponseModel from './model/ReportResponseModel';
import RolagemRequest from './model/RolagemRequest';
import SicredErrorResponse from './model/SicredErrorResponse';
import SicredVersao from './model/SicredVersao';
import AcessoApi from './api/AcessoApi';
import CobrancaApi from './api/CobrancaApi';
import ContratoApi from './api/ContratoApi';
import VersaoApi from './api/VersaoApi';


/**
* AutoAtendimento.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var ApiPortalAutoAtendimento = require('index'); // See note below*.
* var xxxSvc = new ApiPortalAutoAtendimento.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new ApiPortalAutoAtendimento.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new ApiPortalAutoAtendimento.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new ApiPortalAutoAtendimento.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version v1
*/
export default {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The Acesso model constructor.
     * @property {module:model/Acesso}
     */
    Acesso,

    /**
     * The ContratoCarenciaViewModel model constructor.
     * @property {module:model/ContratoCarenciaViewModel}
     */
    ContratoCarenciaViewModel,

    /**
     * The ContratosView model constructor.
     * @property {module:model/ContratosView}
     */
    ContratosView,

    /**
     * The ParcelaBoletoViewModel model constructor.
     * @property {module:model/ParcelaBoletoViewModel}
     */
    ParcelaBoletoViewModel,

    /**
     * The ParcelaCaranciaViewModel model constructor.
     * @property {module:model/ParcelaCaranciaViewModel}
     */
    ParcelaCaranciaViewModel,

    /**
     * The ParcelaRolagem model constructor.
     * @property {module:model/ParcelaRolagem}
     */
    ParcelaRolagem,

    /**
     * The ParcelasResumo model constructor.
     * @property {module:model/ParcelasResumo}
     */
    ParcelasResumo,

    /**
     * The ParcelasView model constructor.
     * @property {module:model/ParcelasView}
     */
    ParcelasView,

    /**
     * The ReportResponseModel model constructor.
     * @property {module:model/ReportResponseModel}
     */
    ReportResponseModel,

    /**
     * The RolagemRequest model constructor.
     * @property {module:model/RolagemRequest}
     */
    RolagemRequest,

    /**
     * The SicredErrorResponse model constructor.
     * @property {module:model/SicredErrorResponse}
     */
    SicredErrorResponse,

    /**
     * The SicredVersao model constructor.
     * @property {module:model/SicredVersao}
     */
    SicredVersao,

    /**
    * The AcessoApi service constructor.
    * @property {module:api/AcessoApi}
    */
    AcessoApi,

    /**
    * The CobrancaApi service constructor.
    * @property {module:api/CobrancaApi}
    */
    CobrancaApi,

    /**
    * The ContratoApi service constructor.
    * @property {module:api/ContratoApi}
    */
    ContratoApi,

    /**
    * The VersaoApi service constructor.
    * @property {module:api/VersaoApi}
    */
    VersaoApi
};

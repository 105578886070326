/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The ReportResponseModel model module.
* @module model/ReportResponseModel
* @version v1
*/
export default class ReportResponseModel {
    /**
    * Constructs a new <code>ReportResponseModel</code>.
    * @alias module:model/ReportResponseModel
    * @class
    */

    constructor() {
        

        
        

        

        
    }

    /**
    * Constructs a <code>ReportResponseModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ReportResponseModel} obj Optional instance to populate.
    * @return {module:model/ReportResponseModel} The populated <code>ReportResponseModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReportResponseModel();

            
            
            

            if (Object.prototype.hasOwnProperty.call(data, 'base64')) {
                obj['base64'] = ApiClient.convertToType(data['base64'], 'String');
            }

            if (Object.prototype.hasOwnProperty.call(data, 'mimeType')) {
                obj['mimeType'] = ApiClient.convertToType(data['mimeType'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} base64
    */
    base64 = undefined;

    /**
    * @member {String} mimeType
    */
     mimeType = undefined;






}



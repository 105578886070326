/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The ParcelaRolagem model module.
* @module model/ParcelaRolagem
* @version v1
*/
export default class ParcelaRolagem {
    /**
    * Constructs a new <code>ParcelaRolagem</code>.
    * @alias module:model/ParcelaRolagem
    * @class
    * @param parcela {String} 
    * @param vencimento {Date} 
    */

    constructor(parcela, vencimento) {
        

        
        

        this['parcela'] = parcela;this['vencimento'] = vencimento;

        
    }

    /**
    * Constructs a <code>ParcelaRolagem</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ParcelaRolagem} obj Optional instance to populate.
    * @return {module:model/ParcelaRolagem} The populated <code>ParcelaRolagem</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ParcelaRolagem();

            
            
            

            if (Object.prototype.hasOwnProperty.call(data, 'parcela')) {
                obj['parcela'] = ApiClient.convertToType(data['parcela'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'vencimento')) {
                obj['vencimento'] = ApiClient.convertToType(data['vencimento'], 'Date');
            }
        }
        return obj;
    }

    /**
    * @member {String} parcela
    */
    parcela = undefined;
    /**
    * @member {Date} vencimento
    */
    vencimento = undefined;








}



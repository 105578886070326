/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Acesso from '../model/Acesso';
import SicredErrorResponse from '../model/SicredErrorResponse';

/**
* Acesso service.
* @module api/AcessoApi
* @version v1
*/
export default class AcessoApi {

    /**
    * Constructs a new AcessoApi. 
    * @alias module:api/AcessoApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the alterarSenha operation.
     * @callback module:api/AcessoApi~alterarSenhaCallback
     * @param {String} error Error message, if any.
     * @param {'String'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Alterar Senha
     * @param {String} cpfCnpj CPF/CNPJ do cliente
     * @param {String} senhaAtual Senha atualmente utilizada pelo cliente.
     * @param {String} novaSenha Nova senha informada pelo cliente.
     * @param {module:api/AcessoApi~alterarSenhaCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    alterarSenha(cpfCnpj, senhaAtual, novaSenha, callback) {
      let postBody = null;

      // verify the required parameter 'cpfCnpj' is set
      if (cpfCnpj === undefined || cpfCnpj === null) {
        throw new Error("Missing the required parameter 'cpfCnpj' when calling alterarSenha");
      }

      // verify the required parameter 'senhaAtual' is set
      if (senhaAtual === undefined || senhaAtual === null) {
        throw new Error("Missing the required parameter 'senhaAtual' when calling alterarSenha");
      }

      // verify the required parameter 'novaSenha' is set
      if (novaSenha === undefined || novaSenha === null) {
        throw new Error("Missing the required parameter 'novaSenha' when calling alterarSenha");
      }


      let pathParams = {
      };
      let queryParams = {
        'cpfCnpj': cpfCnpj,
        'senhaAtual': senhaAtual,
        'novaSenha': novaSenha
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';

      return this.apiClient.callApi(
        '/api/acesso/alterar-senha', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the cadastroAcesso operation.
     * @callback module:api/AcessoApi~cadastroAcessoCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Acesso} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * CadastroAcesso
     * @param {String} cpfCnpj 
     * @param {String} email 
     * @param {String} confirmaEmail 
     * @param {String} senha 
     * @param {String} token 
     * @param {Object} opts Optional parameters
     * @param {Date} opts.dataNascimento 
     * @param {module:api/AcessoApi~cadastroAcessoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Acesso}
     */
    cadastroAcesso(cpfCnpj, email, confirmaEmail, senha, token, opts, callback) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'cpfCnpj' is set
      if (cpfCnpj === undefined || cpfCnpj === null) {
        throw new Error("Missing the required parameter 'cpfCnpj' when calling cadastroAcesso");
      }

      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling cadastroAcesso");
      }

      // verify the required parameter 'confirmaEmail' is set
      if (confirmaEmail === undefined || confirmaEmail === null) {
        throw new Error("Missing the required parameter 'confirmaEmail' when calling cadastroAcesso");
      }

      // verify the required parameter 'senha' is set
      if (senha === undefined || senha === null) {
        throw new Error("Missing the required parameter 'senha' when calling cadastroAcesso");
      }

      // verify the required parameter 'token' is set
      if (token === undefined || token === null) {
        throw new Error("Missing the required parameter 'token' when calling cadastroAcesso");
      }


      let pathParams = {
      };
      let queryParams = {
        'CpfCnpj': cpfCnpj,
        'DataNascimento': opts['dataNascimento'],
        'Email': email,
        'ConfirmaEmail': confirmaEmail,
        'Senha': senha,
        'Token': token
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Acesso;

      return this.apiClient.callApi(
        '/api/acesso/cadastro', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the confirmaCadastro operation.
     * @callback module:api/AcessoApi~confirmaCadastroCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} token 
     * @param {module:api/AcessoApi~confirmaCadastroCallback} callback The callback function, accepting three arguments: error, data, response
     */
    confirmaCadastro(token, callback) {
      let postBody = null;

      // verify the required parameter 'token' is set
      if (token === undefined || token === null) {
        throw new Error("Missing the required parameter 'token' when calling confirmaCadastro");
      }


      let pathParams = {
        'token': token
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/acesso/confirma-cadastro/{token}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the definiSenhaAcesso operation.
     * @callback module:api/AcessoApi~definiSenhaAcessoCallback
     * @param {String} error Error message, if any.
     * @param {'String'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} senha 
     * @param {String} token 
     * @param {module:api/AcessoApi~definiSenhaAcessoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    definiSenhaAcesso(senha, token, callback) {
      let postBody = null;

      // verify the required parameter 'senha' is set
      if (senha === undefined || senha === null) {
        throw new Error("Missing the required parameter 'senha' when calling definiSenhaAcesso");
      }

      // verify the required parameter 'token' is set
      if (token === undefined || token === null) {
        throw new Error("Missing the required parameter 'token' when calling definiSenhaAcesso");
      }


      let pathParams = {
      };
      let queryParams = {
        'senha': senha,
        'token': token
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';

      return this.apiClient.callApi(
        '/api/acesso/defini-senha', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the reDefiniSenhaAcesso operation.
     * @callback module:api/AcessoApi~reDefiniSenhaAcessoCallback
     * @param {String} error Error message, if any.
     * @param {'String'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} cpfcnpj 
     * @param {String} email 
     * @param {module:api/AcessoApi~reDefiniSenhaAcessoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    reDefiniSenhaAcesso(cpfcnpj, email, callback) {
      let postBody = null;

      // verify the required parameter 'cpfcnpj' is set
      if (cpfcnpj === undefined || cpfcnpj === null) {
        throw new Error("Missing the required parameter 'cpfcnpj' when calling reDefiniSenhaAcesso");
      }

      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling reDefiniSenhaAcesso");
      }


      let pathParams = {
        'cpfcnpj': cpfcnpj,
        'email': email
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';

      return this.apiClient.callApi(
        '/api/acesso/solicitar-redefinicao-senha/{cpfcnpj}/{email}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the reenviarEmail operation.
     * @callback module:api/AcessoApi~reenviarEmailCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Reenviar Email
     * @param {String} cpfCnpj CPF/CNPJ do cliente
     * @param {String} email Email do cliente.
     * @param {module:api/AcessoApi~reenviarEmailCallback} callback The callback function, accepting three arguments: error, data, response
     */
    reenviarEmail(cpfCnpj, email, callback) {
      let postBody = null;

      // verify the required parameter 'cpfCnpj' is set
      if (cpfCnpj === undefined || cpfCnpj === null) {
        throw new Error("Missing the required parameter 'cpfCnpj' when calling reenviarEmail");
      }

      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling reenviarEmail");
      }


      let pathParams = {
      };
      let queryParams = {
        'cpfCnpj': cpfCnpj,
        'email': email
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/acesso/reenviar-email', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the verificarCadastroAtivo operation.
     * @callback module:api/AcessoApi~verificarCadastroAtivoCallback
     * @param {String} error Error message, if any.
     * @param {'Boolean'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * VerificarCadastroAtivo
     * @param {String} cpfCnpj CPF/CNPJ do cliente
     * @param {module:api/AcessoApi~verificarCadastroAtivoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'Boolean'}
     */
    verificarCadastroAtivo(cpfCnpj, callback) {
      let postBody = null;

      // verify the required parameter 'cpfCnpj' is set
      if (cpfCnpj === undefined || cpfCnpj === null) {
        throw new Error("Missing the required parameter 'cpfCnpj' when calling verificarCadastroAtivo");
      }


      let pathParams = {
      };
      let queryParams = {
        'cpfCnpj': cpfCnpj
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Boolean';

      return this.apiClient.callApi(
        '/api/acesso/validar-cadastro', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the verificarCadastroInativo operation.
     * @callback module:api/AcessoApi~verificarCadastroInativoCallback
     * @param {String} error Error message, if any.
     * @param {'Boolean'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * VerificarCadastroAtivo
     * @param {String} cpfCnpj CPF/CNPJ do cliente
     * @param {module:api/AcessoApi~verificarCadastroInativoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'Boolean'}
     */
    verificarCadastroInativo(cpfCnpj, callback) {
      let postBody = null;

      // verify the required parameter 'cpfCnpj' is set
      if (cpfCnpj === undefined || cpfCnpj === null) {
        throw new Error("Missing the required parameter 'cpfCnpj' when calling verificarCadastroInativo");
      }


      let pathParams = {
      };
      let queryParams = {
        'cpfCnpj': cpfCnpj
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Boolean';

      return this.apiClient.callApi(
        '/api/acesso/validar-cadastro-inativo', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }


}

/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
* The Parcela2ViaViewModel model module.
* @module model/Parcela2ViaViewModel
* @version v1
*/
export default class Parcela2ViaViewModel {
    /**
    * Constructs a new <code>Parcela2ViaViewModel</code>.
    * @alias module:model/Parcela2ViaViewModel
    * @class
    */

    constructor() { }

    /**
    * Constructs a <code>Parcela2ViaViewModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Parcela2ViaViewModel} obj Optional instance to populate.
    * @return {module:model/Parcela2ViaViewModel} The populated <code>Parcela2ViaViewModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Parcela2ViaViewModel();

            if (Object.prototype.hasOwnProperty.call(data, 'agencia')) {
                obj['agencia'] = ApiClient.convertToType(data['agencia'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'empresa')) {
                obj['empresa'] = ApiClient.convertToType(data['empresa'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'contrato')) {
                obj['contrato'] = ApiClient.convertToType(data['contrato'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'parcela')) {
                obj['parcela'] = ApiClient.convertToType(data['parcela'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'vencimento')) {
                obj['vencimento'] = ApiClient.convertToType(data['vencimento'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'valor')) {
                obj['valor'] = ApiClient.convertToType(data['valor'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'linhaDigitavel')) {
                obj['linhaDigitavel'] = ApiClient.convertToType(data['linhaDigitavel'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'situacao')) {
                obj['situacao'] = ApiClient.convertToType(data['situacao'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} agencia
    */
    agencia = undefined;
    /**
    * @member {String} empresa
    */
    empresa = undefined;
    /**
    * @member {String} contrato
    */
    contrato = undefined;
    /**
    * @member {String} parcela
    */
    parcela = undefined;
    /**
    * @member {Date} vencimento
    */
    vencimento = undefined;
    /**
    * @member {Number} valor
    */
    valor = undefined;
    /**
    * @member {String} linhaDigitavel
    */
    linhaDigitavel = undefined;
    /**
    * @member {String} situacao
    */
    situacao = undefined;
}
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import axios from "axios";
import "url-search-params-polyfill";
import moment from "moment";

class AuthService {
  isAuthenticated() {
    var expDate = (localStorage.token_expiration != null) ? moment(localStorage.token_expiration) : moment();
    var dateDiff = expDate.diff(moment(), "seconds");

    if (dateDiff <= 300) {
      this.refreshToken();
    }    
    return localStorage.access_token != null && dateDiff > 0;
  }

  verificarAcessoCarencia(callback) {
    let config = this.config();
    let contratoApi = new ApiPortalAutoAtendimento.ContratoApi();
    let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
    defaultClient.basePath = config.endpoints.autoatendimento;
    defaultClient.authentications.oauth2.accessToken = this.accessToken();
    var userData = this.userData();
    contratoApi.podeAcessarSolicitacaoCarencia(userData.Empresa, (error, data) => {
      if (callback) {
        callback((error == null && data));
      }
    });
  }

  verificarDiaria(callback) {
    let config = this.config();
    let contratoApi = new ApiPortalAutoAtendimento.ContratoApi();
    let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
    defaultClient.basePath = config.endpoints.autoatendimento;
    defaultClient.authentications.oauth2.accessToken = this.accessToken();
    var userData = this.userData();
    contratoApi.diariaRodando(userData.Empresa, (error, data) => {
      if (callback) {
        callback((error == null && data));
      }
    });
  }
  
  // podeAcessarSolicitacaoCarencia() {
  //   return String(localStorage.pode_acessar_solicitacao_carencia) == '1';
  // }

  getExpDate() {
    return localStorage.token_expiration != null
      ? moment(localStorage.token_expiration)
      : moment();
  }

  logoff() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userData");
  }

  userData() {
    return JSON.parse(localStorage.userData);
  }

  getAuthScope() {
      const  accessToken = atob(this.accessToken().split('.')[1]);
      return JSON.parse(accessToken).scope;
  }
  
  isFastAccess() {
    return this.getAuthScope().includes('autoatendimento.user-fast-access');
  }

  isFastAccessCompleto() {
    return this.getAuthScope().includes('autoatendimento.admin');
  }

  accessToken() {
    return localStorage.access_token;
  }

  currentRefreshToken() {
    return localStorage.refreshToken;
  }

  config() {
    return JSON.parse(localStorage.config);
  }

  setConfig(config) {
    localStorage.config = JSON.stringify(config);
  }

  refreshToken() {
    let config = this.config();
    const data = new FormData();
    data.append("grant_type", "refresh_token");
    // data.append("username", config.auth.clientId);
    // data.append("password", config.auth.clientSecret);
    data.append("refresh_token", this.currentRefreshToken());

    let options = {
      method: "POST",
      url: config.endpoints.authorization + "/connect/token",
      auth: {
        username: config.auth.clientId,
        password: config.auth.clientSecret
      },
      headers: {
        "Content-Type": "multipart/form-data;",
        "cache-control": "no-cache",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*"
      },
      data: data
    };

    return axios(options).then(response => {
      let responseOK = response && response.status === 200;
      if (responseOK) {
        let jwt = response.data;
        if (jwt && jwt.access_token) {
          if (localStorage) {
            var expiration = moment().add(jwt.expires_in, "seconds");
            localStorage.token_expiration = expiration.format();
            localStorage.access_token = jwt.access_token;
            localStorage.refreshToken = jwt.refresh_token;
            let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
            defaultClient.authentications.oauth2.accessToken = jwt.access_token;
          }
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }

  authenticate(username, password, token) {

    let config = this.config();
    const data = new URLSearchParams({
      "grant_type": config.auth.grantType,
      "username": username,
      "password": password,
      "client_id": config.auth.clientId,
      "client_secret": config.auth.clientSecret,
      "scope": config.auth.scope,
      "token": token,
      "origin": "login-portal"
    });

    let options = {
      method: "POST",
      url: config.endpoints.authorization + "/connect/token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "cache-control": "no-cache",
        'Accept': "*/*",
        "Access-Control-Allow-Origin": "*"
      },
      data: data
    };

    return axios(options).then(response => {
      let responseOK = response && response.status === 200;
      if (responseOK) {
        let jwt = response.data;
        if (jwt && jwt.access_token) {
          if (localStorage) {

            var expiration = moment().add(jwt.expires_in, "seconds");
            localStorage.token_expiration = expiration.format();
            localStorage.access_token = jwt.access_token;
            localStorage.refreshToken = jwt.refresh_token;
            localStorage.userData = JSON.stringify(jwt.UserData);
            localStorage.cpfCnpj = "";
            AuthService.isAuthenticated = true;
            let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
            defaultClient.authentications.oauth2.accessToken = jwt.access_token;
          }
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }

  authenticateFastAccess(username, token) {

    let config = this.config();
    const data = new URLSearchParams({
      "grant_type": config.auth.grantType,
      "username": username,
      "password": "-",
      "client_id": config.auth.clientId,
      "client_secret": config.auth.clientSecret,
      "scope": config.auth.scopeFastAccess,
      "token": token,
      "origin": "login-portal"
    });

    let options = {
      method: "POST",
      url: config.endpoints.authorization + "/connect/token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "cache-control": "no-cache",
        'Accept': "*/*",
        "Access-Control-Allow-Origin": "*"
      },
      data: data
    };

    return axios(options).then(response => {
      let responseOK = response && response.status === 200;
      if (responseOK) {
        let jwt = response.data;
        if (jwt && jwt.access_token) {
          if (localStorage) {

            var expiration = moment().add(jwt.expires_in, "seconds");
            localStorage.token_expiration = expiration.format();
            localStorage.access_token = jwt.access_token;
            localStorage.refreshToken = jwt.refresh_token;
            localStorage.userData = JSON.stringify(jwt.UserData);
            localStorage.cpfCnpj = "";
            AuthService.isAuthenticated = true;
            let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
            defaultClient.authentications.oauth2.accessToken = jwt.access_token;
          }
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }

  authenticateCompleto(username, password, token, cpfCnpj) {

    let config = this.config();
    const data = new URLSearchParams({
      "grant_type": config.auth.grantType,
      "username": username,
      "password": password,
      "client_id": config.auth.clientId,
      "client_secret": config.auth.clientSecret,
      "scope": config.auth.scopeFastAccessCompleto,
      "token": token,
      "origin": "login-portal"
    });

    let options = {
      method: "POST",
      url: config.endpoints.authorization + "/connect/token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "cache-control": "no-cache",
        'Accept': "*/*",
        "Access-Control-Allow-Origin": "*"
      },
      data: data
    };

    return axios(options).then(response => {
      let responseOK = response && response.status === 200;
      if (responseOK) {
        let jwt = response.data;
        if (jwt && jwt.access_token) {
          if (localStorage) {

            var expiration = moment().add(jwt.expires_in, "seconds");
            localStorage.token_expiration = expiration.format();
            localStorage.access_token = jwt.access_token;
            localStorage.refreshToken = jwt.refresh_token;
            localStorage.userData = JSON.stringify(jwt.UserData);
            localStorage.cpfCnpj = cpfCnpj;
            AuthService.isAuthenticated = true;
            let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
            defaultClient.authentications.oauth2.accessToken = jwt.access_token;
          }
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }

}

var AuthServiceContext = (function () {
  var instance;

  function createInstance() {
    var object = new AuthService();
    return object;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    }
  };
})();

export default AuthServiceContext;

<template>
  <!-- Logout Modal-->

  
  <div id="logoutModal" class="modal fade modal-forms" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" style="margin:auto;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Deseja sair?</h5>
          <button class="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body text-center">Clique no botão sair para encerrar sua sessão.</div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
          <a class="btn btn-primary" href="#" v-on:click="logoff">Sair</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthServiceContext from "../services/AuthService";
export default {
  name: "LogoutModal",
  methods: {
    logoff() {
      if (event) {
        event.preventDefault();
      }
      let authService = AuthServiceContext.getInstance();
      authService.logoff();
      $("#logoutModal").modal("hide");
      this.$router.push("/");
    }
  }
};
</script>

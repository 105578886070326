/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The ParcelaCaranciaViewModel model module.
* @module model/ParcelaCaranciaViewModel
* @version v1
*/
export default class ParcelaCaranciaViewModel {
    /**
    * Constructs a new <code>ParcelaCaranciaViewModel</code>.
    * @alias module:model/ParcelaCaranciaViewModel
    * @class
    */

    constructor() {
        

        
        

        

        
    }

    /**
    * Constructs a <code>ParcelaCaranciaViewModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ParcelaCaranciaViewModel} obj Optional instance to populate.
    * @return {module:model/ParcelaCaranciaViewModel} The populated <code>ParcelaCaranciaViewModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ParcelaCaranciaViewModel();

            
            
            

            if (Object.prototype.hasOwnProperty.call(data, 'agencia')) {
                obj['agencia'] = ApiClient.convertToType(data['agencia'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'empresa')) {
                obj['empresa'] = ApiClient.convertToType(data['empresa'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'contrato')) {
                obj['contrato'] = ApiClient.convertToType(data['contrato'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'parcela')) {
                obj['parcela'] = ApiClient.convertToType(data['parcela'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'vencimentoContrato')) {
                obj['vencimentoContrato'] = ApiClient.convertToType(data['vencimentoContrato'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'vencimento')) {
                obj['vencimento'] = ApiClient.convertToType(data['vencimento'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'novoVencimento')) {
                obj['novoVencimento'] = ApiClient.convertToType(data['novoVencimento'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'valor')) {
                obj['valor'] = ApiClient.convertToType(data['valor'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} agencia
    */
    agencia = undefined;
    /**
    * @member {String} empresa
    */
    empresa = undefined;
    /**
    * @member {String} contrato
    */
    contrato = undefined;
    /**
    * @member {String} parcela
    */
    parcela = undefined;
    /**
    * @member {Date} vencimentoContrato
    */
    vencimentoContrato = undefined;
    /**
    * @member {Date} vencimento
    */
    vencimento = undefined;
    /**
    * @member {Date} novoVencimento
    */
    novoVencimento = undefined;
    /**
    * @member {Number} valor
    */
    valor = undefined;








}



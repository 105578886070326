/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import SicredVersao from '../model/SicredVersao';

/**
* Versao service.
* @module api/VersaoApi
* @version v1
*/
export default class VersaoApi {

    /**
    * Constructs a new VersaoApi. 
    * @alias module:api/VersaoApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the versao operation.
     * @callback module:api/VersaoApi~versaoCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/SicredVersao>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/VersaoApi~versaoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/SicredVersao>}
     */
    versao(callback) {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [SicredVersao];

      return this.apiClient.callApi(
        '/api/Versao', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }


}

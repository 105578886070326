/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ParcelasView from './ParcelasView';

/**
* The ParcelasResumo model module.
* @module model/ParcelasResumo
* @version v1
*/
export default class ParcelasResumo {
    /**
    * Constructs a new <code>ParcelasResumo</code>.
    * @alias module:model/ParcelasResumo
    * @class
    */

    constructor() {

    }

    /**
    * Constructs a <code>ParcelasResumo</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ParcelasResumo} obj Optional instance to populate.
    * @return {module:model/ParcelasResumo} The populated <code>ParcelasResumo</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ParcelasResumo();

            if (Object.prototype.hasOwnProperty.call(data, 'parcela')) {
                obj['parcela'] = ApiClient.convertToType(data['parcela'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'qtdParcelas')) {
                obj['qtdParcelas'] = ApiClient.convertToType(data['qtdParcelas'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'vencimento')) {
                obj['vencimento'] = ApiClient.convertToType(data['vencimento'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'valor')) {
                obj['valor'] = ApiClient.convertToType(data['valor'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'valorAtualizado')) {
                obj['valorAtualizado'] = ApiClient.convertToType(data['valorAtualizado'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'valorPago')) {
                obj['valorPago'] = ApiClient.convertToType(data['valorPago'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'desconto')) {
                obj['desconto'] = ApiClient.convertToType(data['desconto'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'situacao')) {
                obj['situacao'] = ApiClient.convertToType(data['situacao'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'valorTotal')) {
                obj['valorTotal'] = ApiClient.convertToType(data['valorTotal'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'totalDesconto')) {
                obj['totalDesconto'] = ApiClient.convertToType(data['totalDesconto'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'totalPagamento')) {
                obj['totalPagamento'] = ApiClient.convertToType(data['totalPagamento'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'simulacao')) {
                obj['simulacao'] = ApiClient.convertToType(data['simulacao'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'totalParcelas')) {
                obj['totalParcelas'] = ApiClient.convertToType(data['totalParcelas'], [ParcelasView]);
            }
        }
        return obj;
    }

    /**
    * @member {String} parcela
    */
    parcela = undefined;
    /**
    * @member {String} qtdParcelas
    */
    qtdParcelas = undefined;
    /**
    * @member {String} vencimento
    */
    vencimento = undefined;
    /**
    * @member {Number} valor
    */
    valor = undefined;
    /**
    * @member {Number} valorAtualizado
    */
    valorAtualizado = undefined;
    /**
    * @member {Number} valorPago
    */
    valorPago = undefined;
    /**
    * @member {Number} desconto
    */
    desconto = undefined;
    /**
    * @member {String} situacao
    */
    situacao = undefined;
    /**
    * @member {Number} valorTotal
    */
    valorTotal = undefined;
    /**
    * @member {Number} totalDesconto
    */
    totalDesconto = undefined;
    /**
    * @member {Number} totalPagamento
    */
    totalPagamento = undefined;
    /**
    * @member {String} simulacao
    */
    simulacao = undefined;
    /**
    * @member {Array.<module:model/ParcelasView>} totalParcelas
    */
    totalParcelas = undefined;
}
/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The ParcelaBoletoViewModel model module.
* @module model/ParcelaBoletoViewModel
* @version v1
*/
export default class ParcelaBoletoViewModel {
    /**
    * Constructs a new <code>ParcelaBoletoViewModel</code>.
    * @alias module:model/ParcelaBoletoViewModel
    * @class
    */

    constructor() {
        

        
        

        

        
    }

    /**
    * Constructs a <code>ParcelaBoletoViewModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ParcelaBoletoViewModel} obj Optional instance to populate.
    * @return {module:model/ParcelaBoletoViewModel} The populated <code>ParcelaBoletoViewModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ParcelaBoletoViewModel();

            
            
            

            if (Object.prototype.hasOwnProperty.call(data, 'empresa')) {
                obj['empresa'] = ApiClient.convertToType(data['empresa'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'agencia')) {
                obj['agencia'] = ApiClient.convertToType(data['agencia'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'contrato')) {
                obj['contrato'] = ApiClient.convertToType(data['contrato'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'parcela')) {
                obj['parcela'] = ApiClient.convertToType(data['parcela'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'pmt')) {
                obj['pmt'] = ApiClient.convertToType(data['pmt'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'saldo')) {
                obj['saldo'] = ApiClient.convertToType(data['saldo'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'dataSaldo')) {
                obj['dataSaldo'] = ApiClient.convertToType(data['dataSaldo'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'codBarras')) {
                obj['codBarras'] = ApiClient.convertToType(data['codBarras'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'linhaDigitavel')) {
                obj['linhaDigitavel'] = ApiClient.convertToType(data['linhaDigitavel'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'boletoPdf')) {
                obj['boletoPdf'] = ApiClient.convertToType(data['boletoPdf'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'vcto')) {
                obj['vcto'] = ApiClient.convertToType(data['vcto'], 'Date');
            }
        }
        return obj;
    }

    /**
    * @member {String} empresa
    */
    empresa = undefined;
    /**
    * @member {String} agencia
    */
    agencia = undefined;
    /**
    * @member {String} contrato
    */
    contrato = undefined;
    /**
    * @member {String} parcela
    */
    parcela = undefined;
    /**
    * @member {Number} pmt
    */
    pmt = undefined;
    /**
    * @member {Number} saldo
    */
    saldo = undefined;
    /**
    * @member {Date} dataSaldo
    */
    dataSaldo = undefined;
    /**
    * @member {String} codBarras
    */
    codBarras = undefined;
    /**
    * @member {String} linhaDigitavel
    */
    linhaDigitavel = undefined;
    /**
    * @member {String} boletoPdf
    */
    boletoPdf = undefined;
    /**
    * @member {Date} vcto
    */
    vcto = undefined;








}



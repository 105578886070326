import Vue from 'vue'
import App from './App.vue'
import router from './router'

import jQuery from 'jquery';
global.jQuery = jQuery;
global.$ = jQuery;

import 'jquery.easing';
import 'parsleyjs';
import 'bootstrap';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
// import 'fullpage.js/vendors/scrolloverflow';
// import fullpage from 'fullpage.js';
// global.fullpage = fullpage;
// let Bootstrap = require('bootstrap');
// import 'bootstrap/dist/css/bootstrap.css';
// import "./assets/banco-renner-financeiro.css";
import "./assets/vendor/fontawesome-free/css/all.min.css";
import './assets/auto-atendimento.css';

import ApiPortalAutoAtendimento from "./api_portal_auto_atendimento/src/index";
// global.ApiPortalAutoAtendimento = ApiPortalAutoAtendimento;

import axios from "axios";
import AuthServiceContext from "./services/AuthService";

// Global
import VueTheMask from 'vue-the-mask';
import VueTimers from "vue-timers";



axios.get("config/config.json").then(response => {
  let config = response.data;
  let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
  defaultClient.basePath = config.endpoints.autoatendimento;
  global.AppConfig = config;
  // AuthService.config = config;
  let authService = AuthServiceContext.getInstance();
  authService.setConfig(config);  
});

// import moment from 'moment';
import * as moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-BR');


Vue.use(VueTheMask);
Vue.use(VueTimers);

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY');
  } else {
    return value != null ? String(value) : '';
  }
});

Vue.filter('formatMonth', function(value) {
  if (value) {
    return moment(value).format('MMMM');
  } else {
    return value != null ? String(value) : '';
  }
});

Vue.filter("formatDateTime", function(value) {
  if (value) {
    return moment(value).format("DD/MM/YYYY HH:mm ");
  } else {
    return value != null ? String(value) : "";
  }
});

var formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});


Vue.filter('formatCurrency', (value) => {
  if (value) {
    return formatter.format(value); 
  } else {
    return '';
  }
});

Vue.filter("formatParcelaStatus", value => {
  if (value == "T") {
    return "Vencida";
  } else if (value == "A") {
    return "Aberta";
  } else if (value == "L") {
    return "Liquidada";
  } else {
    return '';
  }
});

Vue.filter("formatContratoStatus", value => {
  if (value == "T") {
    return "Atrasado";
  } else if (value == "A") {
    return "Aberto";
  } else if (value == "L") {
    return "Liquidado";
  } else {
    return '';
  }
});
// Vue.forceUpdate();


Vue.config.productionTip = false
Vue.config.devtool = 'source-map';
// Vue.config.publicPath =  '/renner.autoatendimento.web/';

!function (a) { a.fn.datepicker.dates["pt-BR"] = { days: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"], daysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"], daysMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"], months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"], monthsShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"], today: "Hoje", monthsTitle: "Meses", clear: "Limpar", format: "dd/mm/yyyy" } }(jQuery);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

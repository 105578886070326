/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
* The ParcelasView model module.
* @module model/ParcelasView
* @version v1
*/
export default class ParcelasView {
    /**
    * Constructs a new <code>ParcelasView</code>.
    * @alias module:model/ParcelasView
    * @class
    */
    
    constructor() {
         
    }

    /**
    * Constructs a <code>ParcelasView</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ParcelasView} obj Optional instance to populate.
    * @return {module:model/ParcelasView} The populated <code>ParcelasView</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ParcelasView();

            if (Object.prototype.hasOwnProperty.call(data, 'parcela')) {
                obj['parcela'] = ApiClient.convertToType(data['parcela'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'qtdParcelas')) {
                obj['qtdParcelas'] = ApiClient.convertToType(data['qtdParcelas'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'vencimento')) {
                obj['vencimento'] = ApiClient.convertToType(data['vencimento'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'valor')) {
                obj['valor'] = ApiClient.convertToType(data['valor'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'valorAtualizado')) {
                obj['valorAtualizado'] = ApiClient.convertToType(data['valorAtualizado'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'desconto')) {
                obj['desconto'] = ApiClient.convertToType(data['desconto'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'valorPago')) {
                obj['valorPago'] = ApiClient.convertToType(data['valorPago'], 'Number');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'situacao')) {
                obj['situacao'] = ApiClient.convertToType(data['situacao'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} parcela
    */
    parcela = undefined;
    /**
    * @member {String} qtdParcelas
    */
    qtdParcelas = undefined;
    /**
    * @member {Date} vencimento
    */
    vencimento = undefined;
    /**
    * @member {Number} valor
    */
    valor = undefined;
    /**
    * @member {Number} valorAtualizado
    */
    valorAtualizado = undefined;
    /**
    * @member {Number} desconto
    */
    desconto = undefined;
    /**
    * @member {Number} valorPago
    */
    valorPago = undefined;
    /**
    * @member {String} situacao
    */
    situacao = undefined;
}
/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ParcelaBoletoViewModel from '../model/ParcelaBoletoViewModel';
import SicredErrorResponse from '../model/SicredErrorResponse';

/**
* Cobranca service.
* @module api/CobrancaApi
* @version v1
*/
export default class CobrancaApi {

    /**
    * Constructs a new CobrancaApi. 
    * @alias module:api/CobrancaApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the boleto operation.
     * @callback module:api/CobrancaApi~boletoCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ParcelaBoletoViewModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} empresa 
     * @param {String} simulacao 
     * @param {module:api/CobrancaApi~boletoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ParcelaBoletoViewModel}
     */
    boleto(empresa, simulacao, callback) {
      let postBody = null;

      // verify the required parameter 'empresa' is set
      if (empresa === undefined || empresa === null) {
        throw new Error("Missing the required parameter 'empresa' when calling boleto");
      }

      // verify the required parameter 'simulacao' is set
      if (simulacao === undefined || simulacao === null) {
        throw new Error("Missing the required parameter 'simulacao' when calling boleto");
      }


      let pathParams = {
        'empresa': empresa,
        'simulacao': simulacao
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ParcelaBoletoViewModel;

      return this.apiClient.callApi(
        '/api/cobranca/boleto/{empresa}/{simulacao}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }


  /**
   * Callback function to receive the result of the boleto operation.
   * @callback module:api/CobrancaApi~boleto2viaCallback
   * @param {String} error Error message, if any.
   * @param {'String'} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

    /**
     * @param {String} empresa 
     * @param {String} agencia 
     * @param {String} contrato 
     * @param {String} parcela 
     * @param {module:api/CobrancaApi~boleto2viaCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    boleto2via(empresa, agencia, contrato, parcela, callback) {
      let postBody = null;

      // verify the required parameter 'empresa' is set
      if (empresa === undefined || empresa === null) {
        throw new Error("Missing the required parameter 'empresa' when calling boleto");
      }

      // verify the required parameter 'agencia' is set
      if (agencia === undefined || agencia === null) {
        throw new Error("Missing the required parameter 'agencia' when calling boleto");
      }

      // verify the required parameter 'contrato' is set
      if (contrato === undefined || contrato === null) {
        throw new Error("Missing the required parameter 'contrato' when calling boleto");
      }

      // verify the required parameter 'parcela' is set
      if (parcela === undefined || parcela === null) {
        throw new Error("Missing the required parameter 'parcela' when calling boleto");
      }

      let pathParams = {
        'empresa': empresa,
        'agencia': agencia,
        'contrato': contrato,
        'parcela': parcela
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';

      return this.apiClient.callApi(
        '/api/cobranca/boleto/{empresa}/{agencia}/{contrato}/{parcela}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the emailBoleto operation.
     * @callback module:api/CobrancaApi~emailBoletoCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ParcelaBoletoViewModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} empresa 
     * @param {String} simulacao 
     * @param {String} email 
     * @param {module:api/CobrancaApi~emailBoletoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ParcelaBoletoViewModel}
     */
    emailBoleto(empresa, simulacao, email, callback) {
      let postBody = null;

      // verify the required parameter 'empresa' is set
      if (empresa === undefined || empresa === null) {
        throw new Error("Missing the required parameter 'empresa' when calling emailBoleto");
      }

      // verify the required parameter 'simulacao' is set
      if (simulacao === undefined || simulacao === null) {
        throw new Error("Missing the required parameter 'simulacao' when calling emailBoleto");
      }

      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling emailBoleto");
      }


      let pathParams = {
        'empresa': empresa,
        'simulacao': simulacao,
        'email': email
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ParcelaBoletoViewModel;

      return this.apiClient.callApi(
        '/api/cobranca/email-boleto/{empresa}/{simulacao}/{email}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the urlImpressaoCarne operation.
     * @callback module:api/CobrancaApi~urlImpressaoCarneCallback
     * @param {String} error Error message, if any.
     * @param {'String'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * UrlImpressaoCarne
     * @param {String} empresa 
     * @param {String} cpfcnpj 
     * @param {String} contrato 
     * @param {module:api/CobrancaApi~urlImpressaoCarneCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    urlImpressaoCarne(empresa, cpfcnpj, contrato, callback) {
      let postBody = null;

      // verify the required parameter 'empresa' is set
      if (empresa === undefined || empresa === null) {
        throw new Error("Missing the required parameter 'empresa' when calling urlImpressaoCarne");
      }

      // verify the required parameter 'cpfcnpj' is set
      if (cpfcnpj === undefined || cpfcnpj === null) {
        throw new Error("Missing the required parameter 'cpfcnpj' when calling urlImpressaoCarne");
      }

      // verify the required parameter 'contrato' is set
      if (contrato === undefined || contrato === null) {
        throw new Error("Missing the required parameter 'contrato' when calling urlImpressaoCarne");
      }


      let pathParams = {
        'empresa': empresa,
        'cpfcnpj': cpfcnpj,
        'contrato': contrato
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';

      return this.apiClient.callApi(
        '/api/cobranca/carne-digital/{empresa}/{cpfcnpj}/{contrato}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }


}

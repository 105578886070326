/**
 * Api Portal AutoAtendimento
 * AutoAtendimento
 *
 * OpenAPI spec version: v1
 * Contact: helpdesk@sicred.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The Acesso model module.
* @module model/Acesso
* @version v1
*/
export default class Acesso {
    /**
    * Constructs a new <code>Acesso</code>.
    * @alias module:model/Acesso
    * @class
    * @param cpfCnpj {String} 
    * @param email {String} 
    * @param confirmaEmail {String} 
    * @param senha {String} 
    * @param token {String} 
    */

    constructor(cpfCnpj, email, confirmaEmail, senha, token) {
        

        
        

        this['cpfCnpj'] = cpfCnpj;this['email'] = email;this['confirmaEmail'] = confirmaEmail;this['senha'] = senha;this['token'] = token;

        
    }

    /**
    * Constructs a <code>Acesso</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Acesso} obj Optional instance to populate.
    * @return {module:model/Acesso} The populated <code>Acesso</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Acesso();

            
            
            

            if (Object.prototype.hasOwnProperty.call(data, 'cpfCnpj')) {
                obj['cpfCnpj'] = ApiClient.convertToType(data['cpfCnpj'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'dataNascimento')) {
                obj['dataNascimento'] = ApiClient.convertToType(data['dataNascimento'], 'Date');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'confirmaEmail')) {
                obj['confirmaEmail'] = ApiClient.convertToType(data['confirmaEmail'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'senha')) {
                obj['senha'] = ApiClient.convertToType(data['senha'], 'String');
            }
            if (Object.prototype.hasOwnProperty.call(data, 'token')) {
                obj['token'] = ApiClient.convertToType(data['token'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} cpfCnpj
    */
    cpfCnpj = undefined;
    /**
    * @member {Date} dataNascimento
    */
    dataNascimento = undefined;
    /**
    * @member {String} email
    */
    email = undefined;
    /**
    * @member {String} confirmaEmail
    */
    confirmaEmail = undefined;
    /**
    * @member {String} senha
    */
    senha = undefined;
    /**
    * @member {String} token
    */
    token = undefined;








}



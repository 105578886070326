import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// import Portal from './views/Portal'
import PortalDefault from './views/PortalDefault';

import AuthServiceContext from './services/AuthService'

let authServiceContext = AuthServiceContext.getInstance();

const router = new Router({
  mode: "history",
  // mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: "/portal",
    //   name: "portal-default",
    //   component: () => import("./views/PortalDefault.vue")
    // },
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/redefine-senha",
      name: "RedefineSenha",
      component: () => import("./views/RedefineSenha.vue")
    },
    {
      path: "/segunda-via",
      name: "SegundaVia",
      component: () => import("./views/SegundaVia.vue")
    },
    {
      path: "/meuscontratos",
      // name: "portal",
      component: PortalDefault,
      children: [
        {
          path: "/",
          name: "meuscontratos",
          component: () => import("./views/MeusContratos.vue")
        },
        {
          path: "/solicitacao-carencia",
          name: "solicitacao-carencia",
          component: () => import("./views/SolicitacaoCarencia.vue")
        },
        // {
        //   path: "/ultimosboletos",
        //   name: "ultimosboletos",
        //   component: () => import("./views/UltimosBoletos.vue")
        // },
        {
          path: "/parcelas",
          name: "parcelas",
          component: () => import("./views/Parcelas.vue")
        },
        {
          path: "/quitarcontrato",
          name: "quitarcontrato",
          component: () => import("./views/QuitarContrato.vue")
        },
        {
          path: "/carnedigital",
          name: "carnedigital",
          component: () => import("./views/CarneDigital.vue")
        }
      ]
    }
    // ,
    // {
    //   path: "/about",
    //   name: "about",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "./views/About.vue")
    // }
  ]
});

router.beforeEach((to, from, next) => {
  
  if (to.path != "/" && to.path != "/redefine-senha" && to.path != "/segunda-via") {
   
    let authenticated = authServiceContext.isAuthenticated();

    if (!authenticated) {
      next("/");
    } else {
      if(authServiceContext.isFastAccess()) {
        if(to.path != '/parcelas' && to.path != '/quitarcontrato') {
          next('/parcelas');
        }
      }
      if(authServiceContext.isFastAccessCompleto()) {
        if(to.path != '/parcelas' && to.path != '/quitarcontrato' && to.path != '/carnedigital') {
          next('/parcelas');
        }
      }
    }  
  }
  next();
});

export default router;